import React, {
  Component,
  // Fragment,
} from 'react';
// import ReactDOM from 'react-dom';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message, Modal } from 'antd';
import { isPhoneNumber } from '../../../utils/common';
// import imgBanner from '../../../common/images/home/banner.png';
// import imgHomeQRcode from '../../../common/images/home/home-QRcode.png';
import imgApplyTrySuccess from '../../../common/images/home/apply-try-success.png';
// import imgVegetableSecretary from '../../../common/images/home/VegetableSecretary.png';
import { sendPhone } from '../../../state/action/phone';
// import SubtractNum from '../../../component/subtractNum/index';

import './banner.less';

@connect((state) => state.phone, { sendPhone })
class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      // clickedSend: false,//是否点击了发送按钮
      visible: false,
      errorPhoneFormat: false, //错误的手机格式
    };
  }
  // componentDidMount() {
  //     const dom = ReactDOM.findDOMNode(this.refModal); //获取元素的dom
  //     console.log('dom',dom);
  //     if (dom) {
  //         dom.className = "aaabbb"
  //     }
  // }

  handleChangePhone = (e) => {
    // console.log(e.target)
    this.setState({
      phone: e.target.value,
    });
  };
  handleSend = async () => {
    // this.setState({
    //     visible: true,
    // });

    console.log('phone', this.state.phone);
    const {
      phone,
      // clickedSend,
    } = this.state;
    if (isPhoneNumber(phone)) {
      //验证手机号码是否正确
      this.setState({ errorPhoneFormat: false });
      // if (!clickedSend) {//60秒内不允许多次点击
      // this.setState({ clickedSend: true });
      // const u = window.navigator.userAgent;
      // const u = 'Mozilla/5.0 (Linux; Android 10; BLA-AL00 Build/HUAWEIBLA-AL00; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/66.0.3359.126 MQQBrowser/6.2 TBS/45016 Mobile Safari/537.36 MMWEBID/2522 MicroMessenger/7.0.10.1580(0x27000AFC) Process/tools NetType/4G Language/zh_CN ABI/arm64';
      // const brand = judgeBrand(u.toLowerCase());
      await this.props.sendPhone({ contactPhone: this.state.phone });
      const { addPhone } = this.props;
      if (addPhone.code === 200) {
        // message.success('谢谢，收到您的电话。');
        this.setState({
          visible: true,
        });
      } else {
        message.info(addPhone.msg);
      }
      // }
    } else {
      this.setState({ errorPhoneFormat: true });
      // message.error('手机号格式不对');
    }
  };

  // canClick = () => {
  //     console.log('canClick()');
  //     this.setState({ clickedSend: false });
  // }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { errorPhoneFormat } = this.state;
    return (
      <div className='home-banner'>
        <div className=''>
          <div className='newBannerTitle'>
            <div className='banner-content1'>
              <div>
                <div>
                  <div>菜小秘</div>
                </div>
                <div className='phone'>
                  <div>60000+果蔬商行都在用的农批账目管理软件</div>
                  <div>
                    <input
                      // type="text"
                      name='fname'
                      placeholder='输入手机号立即试用，和我们一起让农批生意更简单'
                      onChange={this.handleChangePhone}
                      className={`${errorPhoneFormat ? 'errorPhoneFormat' : ''}`}
                      pattern='[0-9]*'
                      type='tel' //调用数字键盘
                    />

                    <button type='button' onClick={this.handleSend}>
                      {' '}
                      立即试用{' '}
                    </button>
                  </div>
                  {errorPhoneFormat && <div>请输入正确的手机号</div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          // ref={c => this.refModal = c}
          title=''
          visible={this.state.visible}
          footer={null}
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          maskClosable={false}
          closable={false}
          className='modal-apply-try'
          style={{ top: 300 }}
        >
          <div className='modal-apply-try-wrap'>
            <div>
              <img src={imgApplyTrySuccess} alt='菜小秘' />
            </div>
            <div>
              我们将在<span>2个</span>工作日内与您联系，
              <br />
              您也可通过电话或微信客服与我们联系。
            </div>
            <button type='button' className='apply-try' onClick={this.handleOk}>
              <span>知道了</span>
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Banner;
