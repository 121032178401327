import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { Row, Col } from 'antd';
// import { withRouter, } from 'react-router-dom';
import './style.less';
// import Header from '../../component/header/index';
// import Footer from '../../component/footer/index';
// import imgBanner from '../../common/images/home/banner.png';
import imgService1 from '../../common/images/home/service1.png';
import imgService1Hover from '../../common/images/home/service1-hover.png';
import imgService2 from '../../common/images/home/service2.png';
import imgService2Hover from '../../common/images/home/service2-hover.png';
import imgService3 from '../../common/images/home/service3.png';
import imgService3Hover from '../../common/images/home/service3-hover.png';
import imgService4 from '../../common/images/home/service4.png';
import imgService4Hover from '../../common/images/home/service4-hover.png';
import imgAliyun from '../../common/images/home/aliyun.png';
import imgPingan from '../../common/images/home/pingan.png';
import imgJiansheyinhang from '../../common/images/home/jiansheyinhang.png';
import imgKr from '../../common/images/home/kr.png';
import imgLieyunwang from '../../common/images/home/lieyunwang.png';
import imgHuxiu from '../../common/images/home/huxiu.png';
import imgTodayIcon from '../../common/images/home/today-icon.png';
import imgSunmi from '../../common/images/home/sunmi.png';
// import imgQRcode from '../../common/images/QRcode.png';

import Title from '../../component/title/index';
import Service from './index/service';
import Use from './index/use';
import HomeBanner from './index/banner';
// import Download from './index/download';
// import News from './index/news';
// import Effect from './index/effect';
import MyCarousel from '../../component/carousel/index';
// import NumAdd from '../../component/addNum/index'

class Home extends Component {
  render() {
    const layoutService = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 };
    const layout4 = { xs: 12, sm: 12, md: 12, lg: 8, xl: 8 };
    // const layout5 = { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 };
    // const layout5 = { xs: 24, sm: 24, md: 8, lg: 8, xl: 8 };
    const layout6 = { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 };
    // const layout7 = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 };
    return (
      <div className='home'>
        {/* {window.navigator.userAgent} */}
        <div className='banner'>
          <HomeBanner />
        </div>
        <div className='content2'>
          <Title>菜小秘，国内专业的农批SAAS服务平台</Title>
          <div className='container home-service'>
            <Row gutter={[60, 60]}>
              <Col {...layoutService}>
                <Service
                  img={imgService1}
                  imgHover={imgService1Hover}
                  title='降本增效'
                  content='提升档口下单、收银、对账等经营效率<br />减少错单、漏单、数字化财务更精细<br />档主数据实时查看，决策判断更准确'
                ></Service>
              </Col>
              <Col {...layoutService}>
                <Service
                  img={imgService2}
                  imgHover={imgService2Hover}
                  title='定制产品'
                  content='定制化增值开发，满足不同场景需求<br />组合式场景组件，打造专属解决方案<br />多智能辅助手段，全面减轻管理负担'
                ></Service>
              </Col>
            </Row>
            <Row gutter={[60, 80]}>
              <Col {...layoutService}>
                <Service
                  img={imgService3}
                  imgHover={imgService3Hover}
                  title='智能安全'
                  content='本地化部署服务，覆盖前中后全流程<br />系统应用强加固，云端存储无惧丢失<br />区块链管理技术，确保数据私密安全'
                ></Service>
              </Col>
              <Col {...layoutService}>
                <Service
                  img={imgService4}
                  imgHover={imgService4Hover}
                  title='专属服务'
                  content='1v1专属客户服务，200+团队随处在<br />7*24小时全程跟踪，让问题高效反馈<br />全国31个省覆盖，让距离不成问题'
                ></Service>
              </Col>
            </Row>
          </div>
        </div>

        <div className='content3'>
          <Title>菜小秘正在为客户经营管理提供信息化解决方案</Title>
          <div className='container'>
            <MyCarousel />
          </div>
        </div>
        <div className='content4'>
          <Title>超过60000+农贸商行正在使用菜小秘</Title>
          <div className='container'>
            <Row type='flex' justify='space-between' align='middle'>
              <Col {...layout4}>
                <Use title='31' smallTitle='' content='省份覆盖'></Use>
              </Col>
              <Col {...layout4}>
                <Use title='200' smallTitle='+' content='技术研发团队'></Use>
              </Col>
              <Col {...layout4}>
                <Use title='253' smallTitle='+' content='覆盖城市'></Use>
              </Col>
              <Col {...layout4}>
                {/**title="4000" */}
                <Use title='600000' smallTitle='+' content='累计上下游用户'></Use>
              </Col>
              <Col {...layout4}>
                <Use title='1200' smallTitle='+' content='覆盖市场'></Use>
              </Col>
              <Col {...layout4}>
                <Use title='1000000' smallTitle='+' content='服务从业者'></Use>
              </Col>
            </Row>
          </div>
        </div>
        <div className='content6'>
          <Title>战略合作</Title>
          <div className='container'>
            <Row type='flex' justify='space-between' align='middle'>
              <Col {...layout6}>
                <img src={imgAliyun} alt='' style={{ width: '2.1rem' }} />
              </Col>
              <Col {...layout6}>
                <img src={imgKr} alt='' style={{ width: '.92rem' }} />
              </Col>
              <Col {...layout6}>
                <img src={imgJiansheyinhang} alt='' style={{ width: '2.72rem' }} />
              </Col>
              <Col {...layout6}>
                <img src={imgPingan} alt='' style={{ width: '1.42rem' }} />
              </Col>
            </Row>
            <Row type='flex' justify='space-between' align='middle'>
              <Col {...layout6}>
                <img src={imgLieyunwang} alt='' style={{ width: '1.72rem' }} />
              </Col>
              <Col {...layout6}>
                <img src={imgHuxiu} alt='' style={{ width: '.69rem' }} />
              </Col>
              <Col {...layout6}>
                <img src={imgTodayIcon} alt='' style={{ width: '1.85rem' }} />
              </Col>
              <Col {...layout6}>
                <img src={imgSunmi} alt='' style={{ width: '1.82rem' }} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
